.add-new-post__editor .ql-container,
.add-new-post__editor .ql-editor {
  min-height: 400px;
}

.gDllUf {
  display: inherit !important;
}

.custom-control {
  padding-left: 0% !important;
}

#formFile {
  display: none;
}

#form-File {
  display: none;
}

.border-file {
  text-align: center;
  padding: 3%;
  border: 1px solid #e1e5eb;
  width: 300px;
  border-radius: 3px;

}

:root {
  --card-line-height: 1.2em;
  --card-padding: 0.1em;
  --card-radius: 0.5em;
  --color-green: #007bff;
  --color-orange: #ff3d06;
  --color-gray: #e2ebf6;
  --color-dark-gray: #c4d1e1;
  --radio-border-width: 2px;
  --radio-size: 1.5em;
}




.card {
  background-color: #fff;
  border-radius: var(--card-radius);
  position: relative;
}

.card:hover {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
}

.radio {
  font-size: inherit;
  margin: 0;
  position: absolute;
  right: calc(var(--card-padding) + var(--radio-border-width));
  top: calc(var(--card-padding) + var(--radio-border-width));
}

@supports(-webkit-appearance: none) or (-moz-appearance: none) {
  .radio {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #fff;
    border: var(--radio-border-width) solid var(--color-gray);
    border-radius: 50%;
    cursor: pointer;
    height: var(--radio-size);
    outline: none;
    transition:
      background 0.2s ease-out,
      border-color 0.2s ease-out;
    width: var(--radio-size);
  }

  .card:hover .radio {
    border-color: var(--color-dark-gray);
  }
}

.radio:checked {
  border-color: var(--color-orange);
}

.radio:checked{  
    background: var(--color-orange);
    border-color: var(--color-orange);
}

.radio::after {
  border: var(--radio-border-width) solid #fff;
  border-top: 0;
  border-left: 0;
  content: '';
  display: block;
  height: 0.75rem;
  left: 25%;
  position: absolute;
  top: 50%;
  transform:
    rotate(45deg) translate(-50%, -50%);
  width: 0.375rem;
}

.plan-details {
  border: var(--radio-border-width) solid var(--color-gray);
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: var(--card-padding);
  transition: border-color 0.2s ease-out;
}

.card:hover .plan-details {
  border-color: var(--color-dark-gray);
}

.radio:checked ~ .plan-details {
  /*border-color: var(--color-orange);*/
  /*box-shadow: 10px 10px 10px  var(--color-orange);*/
  /*-webkit-box-shadow: 0 10px 30px var(--color-orange);*/
  /*-moz-box-shadow: 0 0 20px var(--color-orange);*/
  /*-webkit-box-shadow: 0 0 20px var(--color-orange);*/
  box-shadow: 0 0 20px var(--color-orange);
  border: 2px solid var(--color-orange);
  /*box-shadow: 0 5px 5px 5px var(--color-orange);*/
}

.radio:focus~.plan-details {
  box-shadow: 0 0 0 2px var(--color-dark-gray);
}

.radio:disabled~.plan-details {
  color: var(--color-dark-gray);
  cursor: default;
}

.radio:disabled~.plan-details .plan-type {
  color: var(--color-dark-gray);
}

.card:hover .radio:disabled~.plan-details {
  border-color: var(--color-gray);
  box-shadow: none;
}

.card:hover .radio:disabled {
  border-color: var(--color-gray);
}

.plan-type {
  color: var(--color-orange);
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1em;
}

.plan-cost {
  font-size: 2.5rem;
  font-weight: bold;
  padding: 0.5rem 0;
}

.slash {
  font-weight: normal;
}

.plan-cycle {
  font-size: 2rem;
  font-variant: none;
  border-bottom: none;
  cursor: inherit;
  text-decoration: none;
}

.hidden-visually {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}